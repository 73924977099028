<template>
  <div class="layout" v-if="showDom" v-title data-title="首页">
    <Layout>
      <loading v-if="loading" :loadingText="loadingText" />
      <!-- 头部 -->
      <Header />
      <!-- 内容 -->
      <Content style="background: #fff">
        <div v-if="showDom">
          <router-view />
        </div>
      </Content>
      <!-- 底部 -->
      <Footer />
    </Layout>
  </div>
</template>

<script>
import Vue from 'vue'
import Header from '@/components/common/header/header.vue'
import Footer from '@/components/common/footer/footer.vue'

export default {
  name: 'frame',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      loading: false,
      loadingText: '',
      showDom: true,
    }
  },
  computed: {},
  watch: {
    '$route.path'() {
      this.showDom = false
      this.$nextTick(() => {
        this.showDom = true
      })
    },
  },
  methods: {},
  beforeCreate() {
    Vue.prototype.$loading = {
      show: () => {
        this.loading = true
      },
      hide: () => {
        this.loading = false
        this.loadingText = ''
      },
      text: (loadingText) => {
        this.loadingText = loadingText
      },
    }
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.layout {
  // border: 1px solid #d7dde4;
  // background-color: #ffffff;
  // border-radius: 4px;
  position: relative;
  overflow: hidden;
}
</style>
