<template>
  <div class="header-container">
    <!-- 头部欢迎信息 -->
    <HeaderWelcome></HeaderWelcome>
    <div class="top-system">
      <div class="name">武汉市前期物业管理电子招投标系统</div>
    </div>
    <!-- 菜单导航栏 -->
    <div class="top-nav">
      <el-menu
        :default-active="activeName"
        mode="horizontal"
        background-color="#4184F4"
        text-color="#fff"
        active-text-color="#fff"
      >
        <el-menu-item
          v-for="item in menus"
          :key="item.key"
          :index="item.key"
          :route="item.path"
          @click="goNavPage(item)"
          >{{ item.name }}</el-menu-item
        >
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import { removeToken } from '@/axios/api/topHeader.js'
import HeaderWelcome from './vue/header-welcome.vue'

export default {
  components: {
    HeaderWelcome,
  },
  data() {
    return {
      poptipVisible: false,
      menus: [
        {
          key: 'index',
          path: '/index',
          name: '首页',
        },
        {
          key: 'announcement',
          path: '/announcement',
          name: '项目公告',
        },
        {
          key: 'download',
          path: '/download',
          name: '下载中心',
        },
        {
          key: 'notice',
          path: '/notice',
          name: '重要通知',
        },
        {
          key: 'policyDocument',
          path: '/policyDocument',
          name: '政策文件',
        },
        {
          key: 'helpCenter',
          path: '/helpCenter',
          name: '帮助中心',
        },
      ],
      activeIndex: 'index',
    }
  },
  computed: {
    ...mapState(['userInfo', 'systemEntity', 'options']),
    activeName() {
      return this.$route.name.split('-')[0]
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    goNavPage(item) {
      this.$router.push({
        path: item.path,
        query: { name: item.name },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.header-container {
  background: #fff;
  .top-system {
    width: 100%;
    min-width: 1200px;
    background: #fff;
    .name {
      width: 1200px;
      margin: 0px auto;
      height: 100px;
      line-height: 100px;
      color: #666666;
      font-size: 30px;
      font-weight: 400;
    }
  }
  .top-nav {
    width: 100%;
    min-width: 1200px;
    background: #4184f4;
    ::v-deep .el-menu {
      width: 1200px;
      margin: 0px auto;
      height: 42px;
      .el-menu-item {
        width: 200px;
        text-align: center;
        height: 42px;
        line-height: 42px;
        font-size: 16px;
        font-weight: 600;
        &.is-active {
          font-weight: bold;
          background-color: rgba(255, 255, 255, 0.39) !important;
          border-bottom-width: 4px;
        }
      }
    }
  }
}
</style>
