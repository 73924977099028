<template>
  <div class="footer-container">
    <div class="footer-info-wrap">
<!--      <span class="title">前期物业交易系统</span>-->
      <span class="copyright"
        >主办单位：武汉市住房和城市更新局</span
      >
      <span class="address"
        >地<span>址：湖北省武汉市江岸区高雄路166号</span></span
      >
      <span class="code">邮<span>编：430015</span></span>
      <span class="company">备案号：鄂ICP备2023013068号</span>
    </div>
    <!-- <div class="footer-copyright">
      Copyright © 2022 | 鄂ICP备18026180号-1 | <img style="margin: 0 4px 4px 0;" src="./image/badge.png" alt="" />鄂公网安备 42010602003997号
    </div> -->
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
}
</script>

<style lang="less" scoped>
.footer-container {
  width: 100%;
  height: 322px;
  background: #212121;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .footer-info-wrap {
    margin-top: 59px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      color: #ffffff;
      margin-bottom: 26px;
      text-align: center;
    }
    .copyright,
    .address,
    .code {
      span {
        margin-left: 28px;
      }
    }
    .copyright,
    .address,
    .code,
    .company {
      font-size: 14px;
      font-weight: 400;
      line-height: 34px;
      color: #cccccc;
    }
  }
  .footer-copyright {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 34px;
    color: #ffffff;
  }
}
</style>
